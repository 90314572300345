@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .link {
    @apply cursor-pointer;
  },
  .pointer {
    @apply cursor-pointer;
  }
}
 