.typing::after {
  content: "";
  animation: blink 1s infinite ease;
  border-right: 3px solid black;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#div {
  width: 20px;
  height: 10px;
  background-color: white;
  position: relative;
  animation-name: example;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes example {
  0%   {left:0px; top:0px;}
  25%  {left:200px; top:0px;}  
  50%  {left:200px; left:0px;}
}

#loading-bar{
  top: 50%;
  overflow: hidden;
  background-color: "#000";
}
#loading-bar:before{
  display: block;
  position: relative;
  content: "";
  left: -200px;
  width: 8px;
  height: 8px;
  background-color: white;
  -webkit-animation: loading 4s linear infinite;
  animation: loading 4s linear infinite;
}
@keyframes loading {
  from{left: -200px;width: 30%;}
  50%{width: 30%;}
  70%{width: 30%;}
  80%{width: 30%;}
  95%{width: 30%;}
  to{left: 100%;}
}